import React from "react"

import animatedLineDrawing from '../../../images/urbanhus/urbanhus-line-drawing.gif'

const UrbanhusCompOne = () => {
  return (
    <section className="ProjectSection ProjectSection--UrbanhusOne outer-padding-y outer-padding-x bg-white">
      <div>
        <figure className="fadein">
          <img src={animatedLineDrawing} alt="Animated svg illustration"/>
        </figure>
      </div>
      <div>
        <figure className="fadein">
          <img src={animatedLineDrawing} alt="Animated svg illustration"/>
        </figure>
      </div>
      <div>
        <figure className="fadein">
          <img src={animatedLineDrawing} alt="Animated svg illustration"/>
        </figure>
      </div>
    </section>
  )
}

export default UrbanhusCompOne
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../../components/seo"
import ProjectsData from "../../data/projects"
import InnerLayout from "../../components/InnerLayout"
import InnerWrapper from "../../components/InnerWrapper"
import ProjectHero from "../../components/project/Hero"
import ProjectWrapper from "../../components/project/Wrapper"
import ProjectIntro from "../../components/project/Intro"
import ProjectSection from "../../components/project/Section"
import UrbanhusCompOne from "../../components/project/urbanhus/UrbanhusCompOne"
import ProjectNext from "../../components/project/Next"

import urbanhusRecording from '../../images/urbanhus/urbanhus-recording.mp4'

const Urbanhus = (pageContext) => {
  const projectsData = ProjectsData();
  const projectId = 3;
  const projectData = projectsData.find(data => data.id === projectId);

  const images = useStaticQuery(graphql`
    query {
      urbanhusMockupOne: file(relativePath: { eq: "urbanhus/urbanhus-home-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      urbanhusMockupTwo: file(relativePath: { eq: "urbanhus/urbanhus-houses-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      urbanhusMockupThree: file(relativePath: { eq: "urbanhus/urbanhus-blog-mockup.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      urbanhusImage: file(relativePath: { eq: "urbanhus/urbanhus-house.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const contentSectionOne = (
    <div>
      <h3>Minimalistic design and architectural details</h3>
      <p>A web platform that inspires with the houses at the center stage in high quality 3D rendered images combined with dynamic shapes and lines.</p>
      <ul className="list-unstyled"><li>- Clean design</li><li>- House picker module</li><li>- Inspiration blog</li><li>- Drawing effect on lines and shapes</li><li>- Day and night toggle</li><li>- Custom dealership locator map</li><li>- CMS integration allowing easy editing and creation of pages, houses, dealers and employees</li><li>- Gallery functionality</li></ul>
    </div>
  );

  return (
    <InnerLayout noTopPadding>
      <SEO title="Urbanhus project" bodyClass="ProjectPage" />
      <ProjectHero 
        title={projectData.title}
        subtitle={projectData.excerpt}
        color={projectData.color}
        image={projectData.image}
      />
      <InnerWrapper>
        <ProjectWrapper>
          <ProjectIntro
            client={projectData.client}
            website={projectData.website}
            agency={projectData.agency}
            year={projectData.year}
            category={projectData.category}
            role={projectData.role}
            technologies={projectData.technologies}
            problem="Urbanhus (Urban house) is a Norwegian house developer who builds and designs modern homes and cabins. The houses are inspired by scandinavian nature and design. Their process spans from idea to completion and the architects plan and draw houses tailored to the ideas and identity of the individual client. Coming into the project Urbanhus website and online identity did not reflect their unique process, brand and houses."
            solution="The new website features minimalistic design with focus on the houses and their distinct details. Self-drawing lines and shapes represent the architectural process from drawing board to finished home. A night and daylight toggle creates a dynamic scene showcasing the houses from dusk till dawn in the scandinavian nature. The new solution is a great platform for Urbanhus to tell their brand story, inspire customers and attract new dealers."
          />
          <ProjectSection
            type="columns"
            bgcolor="lightgray"
            content={contentSectionOne}
            images={[images.urbanhusMockupOne.childImageSharp.fluid]}
          />
          <UrbanhusCompOne />
          <ProjectSection
            type="image-columns"
            bgcolor="lightgray"
            content={contentSectionOne}
            images={[images.urbanhusMockupTwo.childImageSharp.fluid, images.urbanhusMockupThree.childImageSharp.fluid]}
            reversed
          />
          <ProjectSection
            type="default"
            bgcolor="white"
            video={urbanhusRecording}
          />
          <ProjectSection
            bgcolor="white"
            images={[images.urbanhusImage.childImageSharp.fluid]}
            fullwidth
          />
          <ProjectNext project={3} />
        </ProjectWrapper>
      </InnerWrapper>
    </InnerLayout>
  )
}

export default Urbanhus